/**
 * Load svg via ajax. Based on https://www.npmjs.com/package/webpack-svgstore-plugin with modifications to use 'withCredentials'
 * @param  {string} url path to svg sprite
 * @see: https://www.npmjs.com/package/webpack-svgstore-plugin
 */
export default function svgXHR(options) {
	const url = (options && options && options.filename)
		? options.filename
		: null;

	if (!url) return false;

	const ajax = new XMLHttpRequest();
	const baseUrl = (typeof window.baseUrl !== 'undefined')
		? window.baseUrl
		: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}`;

	const path = (`${baseUrl}/${url}`).replace(/([^:]\/)\/+/g, '$1');

	ajax.withCredentials = true;
	ajax.open('GET', path, true);
	ajax.onprogress = () => {};
	ajax.onload = function onload() {
		document.body.insertBefore(ajax.responseXML.documentElement, document.body.childNodes[0]);
	};
	ajax.send();

	return true;
}
